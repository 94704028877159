.form-page-link-btn {
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: bold;

  &:hover {
    color: #e95d0f !important;
    font-weight: bold !important;
  }
}

.form-content-item {
  border: solid 1px #000;
  border-radius: 12px;
  padding: 12px;
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: #002350;
  word-break: break-all;

  .page-thumbnail-img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .page-number {
    font-size: 16px;
    display: block;
  }

  .page-title {
    font-size: 12px;
    display: block;
  }
}