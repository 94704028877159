$z-index-overlay: 1;

$white-01: #fff;
$white-02: rgba( 255,255,255,0.5 );

$black-01: #000;
$black-02: rgba( 0,0,0,0.16 );
$black-03: rgba( 0,0,0,0.1 );
$black-04: rgba( 0,0,0,0.05 );

$grey-01: #707070;
$grey-02: #e2e2e2;
$grey-03: #d2d2d2;

$blue-01: #43425d;
$blue-02: #a5a4bf;

$purple-01: #80437a;