.mr-15 {
  margin-right: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.btn-md {
  height: 45px !important;
  text-transform: capitalize !important;
  color: $white-01 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.text-left {
  text-align: left;
}

.title-divider {
  color: $grey-03;
  margin-top: 10px;
  margin-bottom: 25px;
}

.text-capitalise {
  text-transform: uppercase;
}