.kws-input {
  width: 100%;
}

.primary-button {
  width: 185px;
  height: 50px;
  // background-color: $grey-02 !important;
  outline: none !important;
}

.primary-button-text {
  font-size: 18px;
}

.primary-button-forgot-password {
  width: 185px;
  height: 50px;
  outline: none !important;
  margin-left: 20px !important;
}